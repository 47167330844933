/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

body{
  background: rgb(34,193,195);
  background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  font-family: "work sans";
}

html{
  font-size: 100%;
} */

 .custom-toast {
 
        /* background: #00BF91 !important; */
        /* height: 100% !important; */
     position:absolute;
     top: 60px;
     /* left: 55%; */
     font-size: large;
     color: black;

    }
